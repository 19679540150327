import React from "react";
import styled from "styled-components";

import { Image, Flex, Box, Link as L, Heading as H, Text as T } from "rebass";
import s1 from "../sketch/rotate";
import s2 from "../sketch/bubbles";
import s3 from "../sketch/lines";

const sketch = [s1, s2, s3];
const randomSketch = sketch => Math.floor(Math.random() * sketch.length) || 0;
const sketchList = ["expand", "bubbles", "lines"];

const theme = {
  primary: "#353940",
  secondary: "#706b6c",
  text: "#76818E",
  bg: "#fff"
};

const social = [
  {
    name: "Instagram",
    url: "https://www.instagram.com/am.id.a/"
  },
  {
    name: "Github",
    url: "https://github.com/mediaupstream"
  }
  // {
  //   name: "linkedin",
  //   url: "https://www.linkedin.com/in/derekanderson523/"
  // }
];

const projects = [
  {
    name: "Emoji Encoder",
    url: "/emoji-encode"
  },
  {
    name: "Lines",
    url: "/sandbox/lines"
  },
  // {
  //   name: "Love Growth Cash",
  //   url: "/love-growth-cash"
  // },
  {
    name: "Color Box",
    url: "/color-box"
  },
  // {
  //   name: "Game Grid",
  //   url: "http://northern.lights.mn/projects/game-grid-working/"
  // },
  {
    name: "Cube Draw",
    url: "/sandbox/cube-draw/"
  },
  // {
  //   name: "Paint Spread",
  //   url: "/sandbox/paint-spread"
  // },
  // {
  //   name: "Tic Tac Toe",
  //   url: "/ttt-react-animated"
  // }
  {
    name: "Axiom Pumpkin",
    url: "/sandbox/axiom-pumpkin"
  }
];

const Section = props => (
  <Box px={[4, 4, 5]} py={[3, 4]} mx={[-4, -4, -5]} my={0} {...props} />
);

const SocialLinks = props => (
  <>
    <Text
      fontWeight={200}
      fontSize={4}
      mb={2}
      color="#fff"
      children="Social:"
    />

    {social.map(item => (
      <Text fontSize={2} key={item.name}>
        <Link
          href={item.url}
          color="#fff"
          target="_blank"
          py={2}
          style={{ display: "block", whiteSpace: "nowrap" }}
        >
          {item.name}
        </Link>
      </Text>
    ))}
  </>
);

const Divider = styled(Box).attrs({
  as: "hr",
  my: 3
})`
  box-sizing: border-box;
  margin-top: 16px;
  margin-bottom: 16px;
  border-top: 8px dotted ${theme.primary};
  height: 2px;
  opacity: 0.1;
`;

const SmallLink = styled(p => <L {...p} />)`
  padding-left: 0.5rem;
  text-decoration: none;
  color: #777;
  opacity: 0.7;
  display: inline-block;
  text-transform: lowercase;
  &.active,
  &:hover,
  &:active,
  &:focus {
    opacity: 1;
  }
  &.active {
    text-decoration: underline;
  }
`;

const Link = styled(p => <L color="#1f769d" {...p} />)`
  border: none;
  text-decoration: none;
  opacity: 0.4;
  display: inline-block;
  transform-origin: top left;
  transition: transform 0.1s linear, opacity 0.1s linear;
  &:hover,
  &:active,
  &:focus,
  &.active {
    transform: scale(1.1);
    opacity: 1;
  }
`;
const Text = props => <T fontWeight={300} color={theme.text} {...props} />;
const Heading = props => (
  <H color={theme.primary} fontWeight={400} {...props} />
);

const Content = styled(Box)`
  transform-style: preserve-3d;
  z-index: 100;

  position: relative;
  background: ${theme.primary};
  padding-bottom: 0;
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.4);

  & > * {
    z-index: 200;
    transform: translateZ(1px);
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    ${"" /* background: repeating-radial-gradient(
      circle at 15%,
      #333,
      #333 10px,
      #eee 0,
      #eee 20px
    ); */}
    opacity: 0.1;
    pointer-events: none;
    z-index: 1;
  }
`;

const Background = styled(Box)`
  z-index: 100;
  background: ${p => `url(${p.bgImage})`};
  background-size: cover;
  cursor: pointer;
`;

const NUM_BG = 8;
const PHOTOSETS = ["Ísland", "Desert"];

const Homepage = () => {
  const [photoset, setPhotoset] = React.useState(PHOTOSETS[0]);
  const [bg, setBg] = React.useState(Math.round(Math.random() * NUM_BG) || 1);
  const changeBg = () => {
    setBg(n => (n < NUM_BG ? n + 1 : 1));
  };
  const bgImage = `/img/bg/${photoset}/${bg}.jpeg`;

  return (
    <Flex flexWrap="wrap" style={{ height: "100vh" }}>
      <Background
        width={[1, 1, 4 / 9]}
        {...{ bgImage }}
        py={7}
        onClick={changeBg}
      />
      <Content px={[4, 4, 5]} width={[1, 1, 5 / 9]}>
        <Section bg="#fff" py={["2rem", "4rem"]}>
          <Box pr="3.5rem">
            <img
              alt="profile avatar"
              src="/img/profile.jpg"
              style={{ width: 100, borderRadius: 600, marginBottom: "2rem" }}
            />
          </Box>
          <Box width={[1, 1, 5 / 7]}>
            <Heading fontSize={5}>Media Upstream</Heading>
            <Text py={2} fontSize={4}>
              Building the Modern Web
            </Text>
            <Text py={2} fontSize={3} lineHeight={1.4}>
              Media Upstream is the personal brand for Minneapolis based
              software developer Derek Anderson
            </Text>

            <Text py={2} fontSize={3} lineHeight={1.4}>
              His work has contributed to the success of a variety of clients
              and startups such as: Target.com, Axiom.co, FrontendMasters.com,
              and more
            </Text>
            <Text pt={4} fontSize={2} lineHeight={1.4}>
              <span style={{ filter: "contrast(1.5)" }}>📷</span>{" "}
              {PHOTOSETS.map((item, i) => (
                <SmallLink
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    setPhotoset(item);
                  }}
                  key={item}
                  className={item === photoset ? "active" : ""}
                  children={item}
                />
              ))}
            </Text>
            <Text fontSize={"12px"} color="#aaa" pt={2}>
              {[...Array(NUM_BG).keys()].map(i => (
                <SmallLink
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    setBg(i + 1);
                  }}
                  key={i}
                  className={i + 1 === bg ? "active" : ""}
                  children={i + 1}
                />
              ))}
            </Text>
          </Box>
        </Section>

        {/*}
        <Section bg={theme.secondary}>
        <Text fontWeight={200} fontSize={4} mb={2}>
            Current:
          </Text>
          <Text color={theme.primary} fontSize={4} mb={2}>
            <Link href="https://www.axiom.co" target="_blank">Axiom</Link>
          </Text> 
          <Divider />
          <Text fontWeight={200} fontSize={4} mb={2}>
            Previous:
          </Text>
          <Flex flexWrap="wrap">
            {[
              "Drinkworks",
              "Target",
              "Frontend Masters",
              "Invisible Friend",
              "Jingit",
              "Voice Hive",
              
            ].map(item => (
              <Box
                key={item}
                width={[1 / 2, 1 / 2, 1 / 3]}
                py={2}
                color="#666"
                children={item}
              />
            ))}
            <Box width={[1 / 2, 1 / 2, 1 / 3]} py={2}>
              <Link
                href="https://www.linkedin.com/in/derekanderson523"
                target="_blank"
                children="more..."
              />
            </Box>
          </Flex>
        </Section>
        */}

        <Section bg={theme.primary} py={["2rem", "4rem"]}>
          <Flex alignItems="top" flexDirection={["row"]}>
            <Box pr="3.5rem">
              <Text
                fontWeight={200}
                fontSize={4}
                mb={2}
                color="#fff"
                children="Experiments:"
              />

              {projects.map(item => (
                <Box key={item.url}>
                  <Link
                    target="_blank"
                    href={item.url}
                    py={2}
                    color="#fff"
                    style={{ display: "block", whiteSpace: "nowrap" }}
                  >
                    {item.name}
                  </Link>
                </Box>
              ))}
            </Box>
            <Box>
              <SocialLinks />
            </Box>
          </Flex>
        </Section>
      </Content>
    </Flex>
  );
};

export default Homepage;
